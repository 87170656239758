import React from 'react';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Container from '../components/Container';
import DefaultLayout from '../layouts/Default';
import DatoSEO from '../components/DatoSEO';
import Hero from '../components/CMSBlocks/Hero';
import CopyBlock from '../components/CopyBlock';
import IntroBanner from '../components/CMSBlocks/IntroBanner';

const OurBrand = ({ data }) => {
	const {
		seoMetaTags,
		hero,
		intro,
		cardSectionContentNode,
		cards,
		videoNode,
		cardsGridSectionContentNode,
		cardsGrid,
	} = data.datoCmsOurBrand;
	const location = useLocation();

	return (
		<>
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			<Hero
				bgImg={hero[0].backgroundImage.url}
				title={hero[0].heroTitle}
				fullWidth
				breadcrumbs={{
					currentPage: 'Our Brand',
				}}
			/>
			<Container>
				<IntroBanner
					primaryImage={intro[0].primaryImage.gatsbyImageData}
					secondaryImage={intro[0].secondaryImage.gatsbyImageData}
					text={intro[0].textNode.childMdx.body}
					fontSize="text-2xl"
				/>
			</Container>

			<div className="py-8 bg-secondary-200">
				<Container>
					<div className="mb-4 text-center [&>*]:text-white">
						<CopyBlock>{cardSectionContentNode.childMdx.body}</CopyBlock>
					</div>
					<div className="lg:flex lg:items-start lg:gap-12">
						{cards.map((card) => (
							<div key={card.id} className="py-4 [&>*]:text-white flex-1">
								<GatsbyImage
									image={card.cardImage.gatsbyImageData}
									alt={card.cardImage?.alt || ''}
									loading="lazy"
									className="mb-6"
								/>
								<CopyBlock>{card.cardTitleNode.childMdx.body}</CopyBlock>
								<CopyBlock>{card.cardContentNode.childMdx.body}</CopyBlock>
							</div>
						))}
					</div>
				</Container>
			</div>

			<div className="py-8 text-center bg-secondary-100">
				<Container className="md:px-32">
					<CopyBlock>{videoNode.childMdx.body}</CopyBlock>
				</Container>
			</div>

			<Container className="my-8 text-center">
				<div className="[&>h2>strong]:text-brand-200 [&>h2>strong]:text-4xl [&>h2>strong]:md:text-5xl [&>h2>strong]:bg-underline [&>h2>strong]:bg-no-repeat [&>h2>strong]:bg-bottom">
					<CopyBlock>{cardsGridSectionContentNode.childMdx.body}</CopyBlock>
				</div>
				<div className="my-8 lg:grid lg:grid-cols-4 lg:gap-4">
					{cardsGrid.map((card) => (
						<div
							key={card.id}
							className="mb-4 lg:mb-0 p-4 [&>*]:text-white [&>p]:text-2xl [&>p>strong>em]:text-2xl [&>p>strong]:text-2xl last:lg:col-span-4 last:lg:mt-8 bg-secondary-200 rounded-xl flex flex-col"
						>
							<CopyBlock>{card.cardTitleNode.childMdx.body}</CopyBlock>
							<div className="[&>*]:text-[#181f2b] h-full p-4 bg-white rounded-xl">
								<CopyBlock>{card.cardContentNode.childMdx.body}</CopyBlock>
							</div>
						</div>
					))}
				</div>
			</Container>
		</>
	);
};

const WrappedOurBrand = (props) => (
	<DefaultLayout fullWidth>
		<OurBrand {...props} />
	</DefaultLayout>
);

export default WrappedOurBrand;

export const query = graphql`
	query OurBrandPage {
		datoCmsOurBrand {
			hero {
				heroTitle
				heroText
				backgroundImage {
					url
				}
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			intro {
				primaryImage {
					gatsbyImageData(placeholder: BLURRED)
				}
				secondaryImage {
					gatsbyImageData(placeholder: BLURRED)
				}
				textNode {
					...DatoCmsTextNodeFragment
				}
			}
			cards {
				cardImage {
					gatsbyImageData(placeholder: BLURRED)
					alt
				}
				...DatoCmsCardFragment
			}
			cardSectionContentNode {
				...DatoCmsTextNodeFragment
			}
			videoNode {
				...DatoCmsTextNodeFragment
			}
			cardsGridSectionContentNode {
				...DatoCmsTextNodeFragment
			}
			cardsGrid {
				...DatoCmsCardFragment
			}
		}
	}
	fragment DatoCmsCardFragment on DatoCmsCard {
		id
		cardTitleNode {
			childMdx {
				body
			}
		}
		cardContentNode {
			childMdx {
				body
			}
		}
	}
	fragment DatoCmsTextNodeFragment on DatoCmsTextNode {
		childMdx {
			body
		}
	}
`;
