import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CopyBlock from '../../CopyBlock';
import Container from '../../Container';

const IntroBanner = ({ primaryImage, secondaryImage, bannerText, title, text }) => (
	<>
		{bannerText && (
			<div className="p-2 text-center text-white bg-black md:p-8">
				<Container>
					<h2 className="max-w-4xl mx-auto text-2xl md:text-3xl">{bannerText}</h2>
				</Container>
			</div>
		)}

		<Container>
			<div className="flex flex-col lg:flex-row lg:gap-12 lg:items-center md:my-12 scroll-mt-24">
				<div
					className={`order-2 lg:order-1 ${
						secondaryImage ? 'lg:flex lg:gap-4 lg:flex-1' : ''
					}`}
				>
					<GatsbyImage
						image={primaryImage}
						className={`w-full aspect-[340/220] lg:aspect-[316/434] ${
							secondaryImage ? 'flex-1 mb-4 lg:mb-0' : ''
						}`}
						alt={primaryImage.alt}
					/>
					{secondaryImage && (
						<GatsbyImage
							image={secondaryImage}
							className="w-full aspect-[340/220] lg:aspect-[316/434] flex-1 mb-4 lg:mb-0"
							alt={secondaryImage.alt}
						/>
					)}
				</div>
				<div className="order-1 mb-8 lg:flex-1 lg:mb-0 lg:order-2 intro-banner">
					{title && (
						<h3 className="my-4 text-2xl font-normal md:text-3xl lg:hidden">{title}</h3>
					)}
					<div className="text-sm">
						<CopyBlock>{text}</CopyBlock>
					</div>
				</div>
			</div>
		</Container>
	</>
);

export default IntroBanner;
